<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center">
          <h2 class="text-2xl font-weight-semibold">
            OTP Verification
          </h2>
        </v-card-title>
        <!-- title -->
        <v-card-text>
          <p class="mb-2">
            Please enter the 6-digit OTP code sent to your phone number
          </p>
        </v-card-text>
        <!-- otp verification form -->
        <v-card-text>
          <v-form
            ref="form"
            method="post"
            action="/"
            lazy-validation
            @submit.prevent="verifyOtp()"
          >
            <v-row>
              <v-col cols="12">
                <v-otp-input
                  v-model="form.otp"
                  :length="6"
                  :rules="otpRules"
                  :error="form.errors.has('otp')"
                  :error-messages="form.errors.get('otp')"
                  required
                ></v-otp-input>
                <small
                  v-show="form.errors.has('otp')"
                  class="validation-error"
                >{{ form.errors.get('otp') }}</small>
              </v-col>
            </v-row>
            <div class="d-flex align-center justify-space-between flex-wrap">
              <div>
                <span>OTP expires in: </span>
                <span class="font-weight-bold">{{ formattedTime }}</span>
              </div>
              <!-- resend otp link -->
              <a
                href="#"
                @click.prevent="resendOtp"
              >Resend OTP</a>
            </div>
            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              :loading="form.busy"
            >
              Verify OTP
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Form from 'vform'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  data: () => ({
    otpRules: [
      v => !!v || 'OTP is required',
      v => (v && v.length === 6) || 'OTP must be 6 digits',
    ],
    form: new Form({
      otp: '',
    }),
    expiresIn: 300, // 5 minutes in seconds
    remainingTime: 300,
    timer: null,
  }),
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.remainingTime / 60)
      const seconds = this.remainingTime % 60

      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer)
    next()
  },
  methods: {
    startTimer() {
      this.remainingTime = this.expiresIn

      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    resendOtp() {
      this.form
        .post('user/resend-otp')
        .then(response => {
          this.$toast.success('OTP resent successfully')
          this.resetTimer()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    verifyOtp() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('user/verify-otp')
          .then(response => {
            // Handle successful OTP verification
            this.$toast.success('OTP verified successfully')
            this.$router.push({ name: 'hotspot-internet-plans' })
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
    resetTimer() {
      this.remainingTime = this.expiresIn
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
